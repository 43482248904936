import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Grid, TextField, MenuItem } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  contact_input_button: {
    margin: `${theme.spacing(4)}px 0px`,
    textTransform: "uppercase"
  }
})); 

const ReturnForm = () => {
  const [state, setState] = React.useState({})
  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value })
  }

  const reasons = [
    {
      value: 'different',
      label: 'Différent de l’image sur le site',
    },
    {
      value: 'late',
      label: 'Article arrivé trop tard',
    },
    {
      value: 'defective',
      label: 'Article défectueux/abîmé',
    },
    {
      value: 'wrong',
      label: 'Mauvais article reçu',
    },
    {
      value: 'styleNotSuit',
      label: 'Le style ne me convient pas',
    },
    {
      value: 'changedMind',
      label: 'j’ai changé d’avis',
    },
    {
      value: 'other',
      label: 'autre',
    },
  ];

  const classes = useStyles();
  return (
    <Grid 
      className={classes.container}
      container
      direction="row"
      alignItems= "center"
      justifyContent="center"
      alignContent="center"
    >
      <form 
        className={classes.container} 
        name="return" 
        method="post" 
        data-netlify="true" 
        data-netlify-honeypot="bot-field" 
        action="/return-confirm/"
        subject="retour"
      >
      <input type="hidden" name="form-name" value="return" />
      <Grid item xs={12}>
        <Grid 
          container 
          direction="row"
          alignItems= "center"
          alignContent="center"
          justifyContent="center"
          spacing={2}
        >
        <Grid item xs={12} md={6}>
          <TextField 
            className={classes.contact_input}
            label="Ton prénom" 
            placeholder=""
            fullWidth
            margin="normal"
            variant="outlined"
            required
            onChange={handleChange}
            name="firstName"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField 
            className={classes.contact_input}
            label="Ton nom" 
            placeholder=""
            fullWidth
            margin="normal"
            variant="outlined"
            required
            onChange={handleChange}
            name="name"
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField 
            className={classes.contact_input}
            label="Ton adresse mail" 
            placeholder="name@example.com"
            fullWidth
            margin="normal"
            variant="outlined"
            required
            onChange={handleChange}
            name="email"
        />
      </Grid>
      <Grid item xs={12} md={5}>
        <TextField 
          className={classes.contact_input}
          label="Ton numéro de commande" 
          placeholder=""
          fullWidth
          margin="normal"
          variant="outlined"
          required
          onChange={handleChange}
          name="order number"
        />
      </Grid>
      <Grid item xs={12} md={7}>
        <TextField 
          className={classes.contact_input}
          select
          fullWidth
          label="Motif du retour" 
          placeholder="Sélectionne le motif de ton retour"
          margin="normal"
          variant="outlined"
          required
          onChange={handleChange}
          name="reason"
          value={state.reason}
        >
          {reasons.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
        </TextField>
      </Grid>
        <Grid item xs={12}>
          <TextField
            className={classes.contact_input}
            variant="outlined"
            fullWidth
            multiline
            rows={5}
            rowsMax={10}
            label="Un message supplémentaire ?" 
            aria-label="message"
            placeholder="C'est à toi, dis-nous tout"
            onChange={handleChange}
            name="message"
          />
        </Grid>
      </Grid>
    </Grid>
    <Grid item>
      <Grid 
        className={classes.contact_input_button}
        container
        direction="column"
        alignContent="center"
      >
        <Button
          type="submit"
          variant="contained"
        >
          envoyer
        </Button>
      </Grid>
    </Grid>
    <div data-netlify-captcha />
  </form>
  </Grid>
  );
};

export default ReturnForm;
