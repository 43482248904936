import React from 'react';
import Layout from '../../components/Layout';
import { graphql } from 'gatsby';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import { MDXRenderer } from "gatsby-plugin-mdx";
import ReturnsForm from "../../components/ReturnsForm.js";

const useStyles = makeStyles(theme => ({
  title: {
    width: "100%",
    textAlign: "center"
  },
  subContainer: {
    textAlign: "start !important",
    "& p" :{
      fontWeight: 500,
      lineHeight: 1.75,
    },
    "& h2": {
      margin: `${theme.spacing(8)}px 0px ${theme.spacing(2)}px`,
      [theme.breakpoints.down('sm')]: {
        margin: `${theme.spacing(0)}px 0px 0px`,
      },
    },
    "& h3": {
      margin: `${theme.spacing(4)}px 0px 0px`,
      [theme.breakpoints.down('sm')]: {
        margin: `${theme.spacing(0)}px 0px 0px`,
      },
    },
    "& h6": {
      margin: `${theme.spacing(4)}px 0px 0px`,
    },
  },
}));

const TransportPage = ({data}) => {
  const { mdx: { frontmatter, body } } = data;
  const classes = useStyles();
  return (
    <Layout pageName="transport">
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
      <Typography className={classes.title} variant="h1">{frontmatter.title}</Typography>
        <Grid
          item
          xs={12} sm={10} md={8} lg={6} xl={5} 
          className={classes.subContainer}
        >
          <MDXRenderer className={classes.containerText}>{body}</MDXRenderer>
          <Typography className={classes.title} variant="h3">Formulaire de retour</Typography>
          <ReturnsForm />
        </Grid>
      </Grid>
    </Layout>
  );
};
export const transportQuery = graphql`
{
  mdx(frontmatter: {name: {eq: "transport"}}) {
    body 
    frontmatter {
      title
    }
  }
}
`
export default TransportPage;